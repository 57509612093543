import { DataResult } from './DataResult';
import { StatisticData } from './Statistic';

export interface AuthorizationDefinition {
  superAdmin: AuthorizationDefinitionRole;
  admin: AuthorizationDefinitionRole;
  basic: AuthorizationDefinitionRole;
  demo: AuthorizationDefinitionRole;
  siteAdmin: AuthorizationDefinitionRole;
  siteOwner: AuthorizationDefinitionRole;
}
export interface AuthorizationDefinitionRole {
  grants: AuthorizationDefinitionGrant[];
  $extend?: Record<string, unknown>;
}

export interface AuthorizationDefinitionGrant {
  resource: Entity;
  action: Action | Action[];
  args?: Record<string, unknown>;
  condition?: AuthorizationDefinitionCondition;
  attributes?: string[];
}

export interface AuthorizationDefinitionCondition {
  Fn: string;
  args: AuthorizationDefinitionConditionArgs|AuthorizationDefinitionConditionArgs[]|AuthorizationDefinitionCondition[]|Record<string, unknown>;
}

export interface AuthorizationDefinitionConditionArgs {
  filters?: string[];
  asserts?: string[];
  metadata?: Record<string, AuthorizationDefinitionFieldMetadata>;
}

export interface AuthorizationDefinitionFieldMetadata {
  visible: boolean;
  enabled: boolean;
  mandatory: boolean;
  values: string[]|boolean[]|number[];
  defaultValue: string|boolean|number;
}

export interface AuthorizationFilter {
  filters: Record<string, unknown>;
  project: string[];
}

export enum Entity {
  SITE = 'Site',
  SITE_AREA = 'SiteArea',
  COMPANY = 'Company',
  CHARGING_STATION = 'ChargingStation',
  CHARGING_STATION_TEMPLATE = 'ChargingStationTemplate',
  CHARGING_STATION_CERTIFICATE = 'ChargingStationCertificate',
  CONNECTOR = 'Connector',
  TENANT = 'Tenant',
  TRANSACTION = 'Transaction',
  REPORT = 'Report',
  USER = 'User',
  USER_SITES = 'UserSites',
  SITE_USERS = 'SiteUsers',
  LOGGING = 'Logging',
  PRICING = 'Pricing',
  PRICING_DEFINITION = 'PricingDefinition',
  BILLING = 'Billing',
  BILLING_PLATFORM = 'BillingPlatform',
  BILLING_ACCOUNT = 'BillingAccount',
  BILLING_TRANSFER = 'BillingTransfer',
  SETTING = 'Setting',
  ASYNC_TASK = 'AsyncTask',
  OCPI_ENDPOINT = 'OcpiEndpoint',
  OICP_ENDPOINT = 'OicpEndpoint',
  CONNECTION = 'Connection',
  ASSET = 'Asset',
  CAR_CATALOG = 'CarCatalog',
  CAR = 'Car',
  INVOICE = 'Invoice',
  TAX = 'Tax',
  REGISTRATION_TOKEN = 'RegistrationToken',
  CHARGING_PROFILE = 'ChargingProfile',
  NOTIFICATION = 'Notification',
  TAG = 'Tag',
  PAYMENT_METHOD = 'PaymentMethod',
  PAYMENT = 'Payment',
  SOURCE = 'Source',
  USER_GROUP = 'UserGroup',
  PLANNING = 'Planning',
  CONSUMPTION = 'Consumption',
  SMART_CHARGING = 'SmartCharging',
  STATISTIC = 'Statistic',
  GRID_MONITORING = 'GridMonitoring',
  PLUG_AND_CHARGE = 'PlugAndCharge',
  SUBSCRIPTION = 'Subscription',
  USER_SUBSCRIPTION = 'UserSubscription',
  SCHEDULER = 'Scheduler',
  BLACKLIST = 'Blacklist',
  RELEASE_NOTES = 'ReleaseNotes',
}

export enum Action {
  READ = 'Read',
  CREATE = 'Create',
  UPDATE = 'Update',
  CANCEL = 'Cancel',
  UPDATE_BY_VISUAL_ID = 'UpdateByVisualID',
  REPLACE = 'Replace',
  REVOKE = 'Revoke',
  DELETE = 'Delete',
  SET_DEFAULT = 'SetDefault',
  LOGOUT = 'Logout',
  LOGIN = 'Login',
  LIST = 'List',
  IN_ERROR = 'InError',
  RESET = 'Reset',
  TRIGGER_MESSAGE = 'TriggerMessage',
  ASSIGN = 'Assign',
  UNASSIGN = 'Unassign',
  CONFIRM = 'Confirm',
  CLEAR_CACHE = 'ClearCache',
  DATA_TRANSFER = 'DataTransfer',
  SYNCHRONIZE = 'Synchronize',
  GET_CONFIGURATION = 'GetConfiguration',
  CHANGE_CONFIGURATION = 'ChangeConfiguration',
  SYNCHRONIZE_CAR_CATALOGS = 'SynchronizeCarCatalogs',
  REMOTE_START_TRANSACTION = 'RemoteStartTransaction',
  REMOTE_STOP_TRANSACTION = 'RemoteStopTransaction',
  REMOTE_FORCE_STOP_TRANSACTION = 'RemoteForceStopTransaction',
  START_TRANSACTION = 'StartTransaction',
  STOP_TRANSACTION = 'StopTransaction',
  UNLOCK_CONNECTOR = 'UnlockConnector',
  AUTHORIZE = 'Authorize',
  SET_CHARGING_PROFILE = 'SetChargingProfile',
  GET_COMPOSITE_SCHEDULE = 'GetCompositeSchedule',
  CLEAR_CHARGING_PROFILE = 'ClearChargingProfile',
  GET_DIAGNOSTICS = 'GetDiagnostics',
  UPDATE_FIRMWARE = 'UpdateFirmware',
  EXPORT = 'Export',
  CHANGE_AVAILABILITY = 'ChangeAvailability',
  REFUND_TRANSACTION = 'RefundTransaction',
  SYNCHRONIZE_BILLING_USER = 'SynchronizeBillingUser',
  BILLING_SETUP_PAYMENT_METHOD = 'BillingSetupPaymentMethod',
  BILLING_PAYMENT_METHODS = 'BillingPaymentMethods',
  BILLING_DELETE_PAYMENT_METHOD = 'BillingDeletePaymentMethod',
  BILLING_CHARGE_INVOICE = 'BillingChargeInvoice',
  BILLING_ACTIVATE_ACCOUNT = 'BillingAccountActivate',
  BILLING_REFRESH_ACCOUNT = 'BillingAccountRefresh',
  BILLING_ONBOARD_ACCOUNT = 'BillingAccountOnboard',
  BILLING_FINALIZE_TRANSFER = 'BillingFinalizeTransfer',
  BILLING_SEND_TRANSFER = 'BillingSendTransfer',
  CHECK_CONNECTION = 'CheckConnection',
  CLEAR_BILLING_TEST_DATA = 'ClearBillingTestData',
  RETRIEVE_CONSUMPTION = 'RetrieveConsumption',
  READ_CONSUMPTION = 'ReadConsumption',
  CREATE_CONSUMPTION = 'CreateConsumption',
  PING = 'Ping',
  GENERATE_LOCAL_TOKEN = 'GenerateLocalToken',
  REGISTER = 'Register',
  TRIGGER_JOB = 'TriggerJob',
  DOWNLOAD = 'Download',
  IMPORT = 'Import',
  ASSIGN_USERS = 'AssignUsers',
  UNASSIGN_USERS = 'UnassignUsers',
  ASSIGN_SITES = 'AssignSites',
  UNASSIGN_SITES = 'UnassignSites',
  ASSIGN_ASSETS = 'AssignAssets',
  UNASSIGN_ASSETS = 'UnassignAssets',
  ASSIGN_CHARGING_STATIONS = 'AssignChargingStations',
  UNASSIGN_CHARGING_STATIONS = 'UnassignChargingStations',
  EXPORT_OCPP_PARAMS = 'ExportOCPPParams',
  MAINTAIN_PRICING_DEFINITIONS = 'MaintainPricingDefinitions',
  RESOLVE = 'Resolve',
  GET_STATUS_NOTIFICATION = 'GetStatusNotification',
  GET_BOOT_NOTIFICATION = 'GetBootNotification',
  RESERVE_NOW = 'ReserveNow',
  UPDATE_OCPP_PARAMS = 'UpdateOCPPParams',
  LIMIT_POWER = 'LimitPower',
  DELETE_CHARGING_PROFILE = 'DeleteChargingProfile',
  GET_OCPP_PARAMS = 'GetOCPPParams',
  UPDATE_CHARGING_PROFILE = 'UpdateChargingProfile',
  DOWNLOAD_QR_CODE = 'DownloadQRCode',
  GENERATE_QR_CODE = 'GenerateQRCode',
  VIEW_USER_DATA = 'ViewUserData',
  SYNCHRONIZE_REFUNDED_TRANSACTION = 'SynchronizeRefundedTransaction',
  READ_ADVENIR_CONSUMPTION = 'ReadAdvenirConsumption',
  PUSH_CDR = 'PushCdr',
  EXPORT_CDR = 'ExportCdr',
  ACTIVE = 'Active',
  COMPLETED = 'Completed',
  REFUNDABLE = 'Refundable',
  REFUND_REPORT = 'RefundReport',
  LIST_CERTIFICATES = 'ListCertificates',
  GET_INSTALLED_CERTIFICATE_IDS = 'GetInstalledCertificateIds',
  INSTALL_CERTIFICATE = 'InstallCertificate',
  DELETE_CERTIFICATE = 'DeleteCertificate',
  SET_NETWORK_PROFILE = 'SetNetworkProfile',
}

export interface AuthorizationContext {
  tagIDs?: string[];
  tagID?: string;
  owner?: string;
  site?: string;
  sites?: string[];
  sitesAdmin?: string[];
  user?: string;
  UserID?: string;
  sitesOwner?: string[];
  company?: string;
  companies?: string[];
  asset?: string;
  assets?: string[];
}

// Entity: Common Authorization Actions (CRUD without Create)
export interface AuthorizationActions {
  canRead?: boolean;
  canUpdate?: boolean;
  canDelete?: boolean;
}

export interface DataResultAuthorizationAttributes {
  projectFields?: string[];
  metadata?: Record<string, AuthorizationDefinitionFieldMetadata>;
}

// Data Result: Common Authorization Action
export interface DataResultAuthorizationActions {
  canCreate?: boolean;
}

// Basic Data Result Authorizations
export interface DataResultAuthorizations extends DataResultAuthorizationAttributes, DataResultAuthorizationActions {
}

export interface CarsAuthorizations extends DataResultAuthorizationAttributes, CarsAuthorizationActions {
}

export interface CarsAuthorizationActions extends DataResultAuthorizationActions {
  canListUsers?: boolean;
  canListCarCatalog?: boolean;
}

export interface CarAuthorizationActions extends AuthorizationActions {
  canListUsers?: boolean;
}

export interface CarCatalogsAuthorizations extends DataResultAuthorizationAttributes, CarCatalogsAuthorizationActions {
}

export interface CarCatalogsAuthorizationActions extends DataResultAuthorizationActions {
  canSync?: boolean;
}

export interface TagsAuthorizations extends DataResultAuthorizationAttributes, TagsAuthorizationActions {
}

export interface TagsAuthorizationActions extends DataResultAuthorizationActions {
  canAssign?: boolean;
  canDelete?: boolean;
  canImport?: boolean;
  canExport?: boolean;
  canUnassign?: boolean;
  canListUsers?: boolean;
  canListSources?: boolean;
}

export interface TagAuthorizationActions extends AuthorizationActions {
  canUnassign?: boolean;
  canAssign?: boolean;
  canListUsers?: boolean;
  canUpdateByVisualID?: boolean;
}

export interface UsersAuthorizations extends DataResultAuthorizationAttributes, UsersAuthorizationActions {
}

export interface UsersAuthorizationActions extends DataResultAuthorizationActions {
  canImport?: boolean;
  canExport?: boolean;
  canListTags?: boolean;
  canListSites?: boolean;
  canListUserGroups?: boolean;
}

export interface UserAuthorizationActions extends AuthorizationActions {
  canAssignSites?: boolean;
  canUnassignSites?: boolean;
  canListSites?: boolean;
  canListTags?: boolean;
  canListPaymentMethods?: boolean;
  canListUserSubscriptions?: boolean;
  canListCompletedTransactions?: boolean;
  canSynchronizeBillingUser?: boolean;
  canMaintainPricingDefinitions?: boolean;
}

export interface UserSitesAuthorizations extends DataResultAuthorizationAttributes, UserSitesAuthorizationActions {
  canAssignSites: boolean;
  canUnassignSites: boolean;
  canListSites: boolean;
  canSetSitesSiteAdmin?: boolean;
  canUnsetSitesSiteAdmin?: boolean;
}

export interface UserSitesAuthorizationActions extends DataResultAuthorizationActions {
}

export interface UserSiteAuthorizationActions extends AuthorizationActions {
  canSetSiteSiteAdmin?: boolean;
  canUnsetSiteSiteAdmin?: boolean;
  canSetSiteSiteOwner?: boolean;
  canUnsetSiteSiteOwner?: boolean;
}

export interface SiteUsersAuthorizations extends DataResultAuthorizationAttributes, SiteUsersAuthorizationActions {
  canUnassignUsers: boolean;
  canAssignUsers: boolean;
  canListUser: boolean;
  canSetUsersSiteAdmin: boolean;
  canUnsetUsersSiteAdmin: boolean;
}

export interface SiteUsersAuthorizationActions extends DataResultAuthorizationActions {
}

export interface SiteUserAuthorizationActions extends AuthorizationActions {
  canSetUserSiteAdmin?: boolean;
  canUnsetUserSiteAdmin?: boolean;
  canSetUserSiteOwner?: boolean;
  canUnsetUserSiteOwner?: boolean;
  canAssignUsers?: boolean;
  canUnassignUsers?: boolean;
}

export interface SubscriptionsAuthorizations extends DataResultAuthorizationAttributes, SubscriptionsAuthorizationActions {
}

export interface SubscriptionsAuthorizationActions extends DataResultAuthorizationActions {
  canListSubscriptions?: boolean;
}

export interface UserSubscriptionsAuthorizations extends DataResultAuthorizationAttributes, UserSubscriptionsAuthorizationActions {
}

export interface UserSubscriptionsAuthorizationActions extends DataResultAuthorizationActions {
  canListUserSubscriptions?: boolean;
  canCancel?: boolean;
}

export interface BlacklistsAuthorizations extends DataResultAuthorizationAttributes, BlacklistsAuthorizationActions {
}

export interface BlacklistsAuthorizationActions extends DataResultAuthorizationActions {
}

export interface AssetsAuthorizations extends DataResultAuthorizationAttributes, AssetsAuthorizationActions {
}

export interface ChargingStationTemplatesAuthorizations extends DataResultAuthorizationAttributes, ChargingStationTemplatesAuthorizationActions {
}

export interface AssetsAuthorizationActions extends DataResultAuthorizationActions {
  canListSites?: boolean;
  canListSiteAreas?: boolean;
}

export interface ChargingStationTemplatesAuthorizationActions extends DataResultAuthorizationActions {
}

export interface AssetAuthorizationActions extends AuthorizationActions {
  canRetrieveConsumption?: boolean;
  canReadConsumption?: boolean;
  canCheckConnection?: boolean;
}

export interface SiteAreasAuthorizations extends DataResultAuthorizationAttributes, DataResultAuthorizationActions {
  canListSites?: boolean;
}

export interface TenantsAuthorizations extends DataResultAuthorizationAttributes, DataResultAuthorizationActions {
}

export interface CompaniesAuthorizations extends DataResultAuthorizationAttributes, DataResultAuthorizationActions {
}

export interface CompanyAuthorizationActions extends AuthorizationActions {
  canMaintainPricingDefinitions?: boolean;
}

export interface SiteAreaAuthorizationActions extends AuthorizationActions {
  canAssignAssets?: boolean;
  canUnassignAssets?: boolean;
  canListAssets?: boolean;
  canAssignChargingStations?: boolean;
  canUnassignChargingStations?: boolean;
  canListChargingStations?: boolean;
  canExportOCPPParams?: boolean;
  canDownloadQrCode?: boolean;
  canMaintainPricingDefinitions?: boolean;
}

export interface SitesAuthorizations extends DataResultAuthorizationAttributes, SitesAuthorizationActions {
}

export interface SitesAuthorizationActions extends DataResultAuthorizationActions {
  canListCompanies?: boolean;
}

export interface SiteAuthorizationActions extends AuthorizationActions {
  canAssignUsers?: boolean;
  canUnassignUsers?: boolean;
  canListUsers?: boolean;
  canExportOCPPParams?: boolean;
  canDownloadQrCode?: boolean;
  canMaintainPricingDefinitions?: boolean;
}

export interface RegistrationTokenAuthorizationActions extends AuthorizationActions {
  canRevoke?: boolean;
}

export interface LogsAuthorizationActions extends AuthorizationActions {
  canExport?: boolean;
}

export interface BillingAccountAuthorizations extends DataResultAuthorizationAttributes, BillingInvoicesAuthorizationActions {
}

export interface BillingInvoicesAuthorizations extends DataResultAuthorizationAttributes, BillingInvoicesAuthorizationActions {
}

export interface BillingTaxesAuthorizations extends DataResultAuthorizationAttributes, BillingTaxesAuthorizationActions {
}

export interface BillingTransfersAuthorizations extends DataResultAuthorizationAttributes, BillingTransfersAuthorizationActions {
}

export interface BillingInvoicesAuthorizationActions extends DataResultAuthorizationActions {
  canListUsers?: boolean;
  canSynchronizeInvoices?: boolean;
}

export interface BillingTaxesAuthorizationActions extends DataResultAuthorizationActions {
}

export interface BillingTransfersAuthorizationActions extends DataResultAuthorizationActions {
  canListUsers?: boolean;
}

export interface BillingInvoiceAuthorizationActions extends AuthorizationActions {
  canDownload?: boolean;
}

export interface BillingPaymentMethodsAuthorizationActions extends DataResultAuthorizationAttributes, DataResultAuthorizationActions {
}

export interface BillingPaymentMethodAuthorizationActions extends AuthorizationActions {
  canSetDefault?: boolean;
}

export interface ChargingStationTemplateAuthorizations extends DataResultAuthorizationAttributes, DataResultAuthorizationActions {
}

export interface ChargingStationTemplateAuthorizationActions extends AuthorizationActions {
}

export interface ChargingStationsAuthorizations extends DataResultAuthorizationAttributes, ChargingStationsAuthorizationActions {
}

export interface PlanningsAuthorizations extends DataResultAuthorizationAttributes, PlanningsAuthorizationActions {
}

export interface PlanningsAuthorizationActions extends DataResultAuthorizationActions {
}

export interface PlanningAuthorizationActions extends AuthorizationActions {
}

export interface ChargingProfilesAuthorizations extends DataResultAuthorizationAttributes, DataResultAuthorizationActions {
  canListChargingStations?: boolean;
  canListSiteAreas?: boolean;
  canListSites?: boolean;
  canListLogs?: boolean;
}

export interface ChargingStationsAuthorizationActions extends DataResultAuthorizationActions {
  canListUsers?: boolean;
  canListSites?: boolean;
  canListSiteAreas?: boolean;
  canListCompanies?: boolean;
  canExport?: boolean;
}

export interface ConnectorAuthorizationActions extends AuthorizationActions {
  canRemoteStopTransaction?: boolean;
  canRemoteForceStopTransaction?: boolean;
  canRemoteStartTransaction?: boolean;
  canUnlockConnector?: boolean;
  canReserveNow?: boolean;
  canCancelReservation?: boolean;
  canReadTransaction?: boolean;
}

export interface ChargingStationAuthorizationActions extends AuthorizationActions {
  canExport?: boolean;
  canListCompanies?: boolean;
  canListSites?: boolean;
  canListSiteAreas?: boolean;
  canListUsers?: boolean;
  canReserveNow?: boolean;
  canReset?: boolean;
  canClearCache?: boolean;
  canGetConfiguration?: boolean;
  canChangeConfiguration?: boolean;
  canSetChargingProfile?: boolean;
  canGetCompositeSchedule?: boolean;
  canClearChargingProfile?: boolean;
  canGetDiagnostics?: boolean;
  canSetNetworkProfile?: boolean;
  canUpdateFirmware?: boolean;
  canRemoteStopTransaction?: boolean;
  canStopTransaction?: boolean;
  canStartTransaction?: boolean;
  canChangeAvailability?: boolean;
  canRemoteStartTransaction?: boolean;
  canUnlockConnector?: boolean;
  canDataTransfer?: boolean;
  canGenerateQRCode?: boolean;
  canDownloadQRCode?: boolean;
  canMaintainPricingDefinitions?: boolean;
  canUpdateOCPPParams?: boolean;
  canLimitPower?: boolean;
  canDeleteChargingProfile?: boolean;
  canGetOCPPParams?: boolean;
  canUpdateChargingProfile?: boolean;
  canPushCdr?: boolean;
  canListCompletedTransactions?: boolean;
  canAuthorize?: boolean;
  canTriggerMessage?: boolean;
  canListCertificates?: boolean;
  canGetInstalledCertificateIds?: boolean;
  canInstallCertificate?: boolean;
  canDeleteCertificate?: boolean;
}

export interface ChargingProfileAuthorizationActions extends AuthorizationActions {
  canReadSiteArea?: boolean;
  canListLogs?: boolean;
}

export interface ChargingStationsCertificatesAuthorizations extends DataResultAuthorizationAttributes, ChargingStationCertificateAuthorizationActions {
}

export interface ChargingStationCertificateAuthorizationActions extends AuthorizationActions {}

export interface TransactionsAuthorizations extends DataResultAuthorizationAttributes, TransactionsAuthorizationActions {
}

export interface TransactionsAuthorizationActions extends DataResultAuthorizationActions {
  canListUsers?: boolean;
  canListCompanies?: boolean;
  canListSites?: boolean;
  canListSiteAreas?: boolean;
  canListChargingStations?: boolean;
  canListTags?: boolean;
  canListCars?: boolean;
  canListCarCatalogs?: boolean;
  canListInvoices?: boolean;
  canListLogs?: boolean;
  canExport?: boolean;
  canDelete?: boolean;
  canSyncRefund?: boolean;
  canRefund?: boolean;
  canReadSetting?: boolean;
}

export interface TransactionAuthorizationActions extends AuthorizationActions {
  canSynchronizeRefundedTransaction?: boolean;
  canRefundTransaction?: boolean;
  canPushCdr?: boolean;
  canGetAdvenirConsumption?: boolean;
  canRemoteStopTransaction?: boolean;
  canRemoteForceStopTransaction?: boolean;
  canGetChargingStationTransactions?: boolean;
  canExportCdr?: boolean;
  canReadChargingStation?: boolean;
}

export interface SettingsAuthorizations extends DataResultAuthorizationAttributes, SettingsAuthorizationActions {
}

export interface SettingsAuthorizationActions extends DataResultAuthorizationActions {
}

export interface SettingAuthorizationActions extends AuthorizationActions {
  canSyncRefund?: boolean;
  canCheckBillingConnection?: boolean;
  canActivateBilling?: boolean;
  canCheckSmartChargingConnection?: boolean;
  canCheckAssetConnection?: boolean;
}

export interface OcpiEndpointsAuthorizations extends DataResultAuthorizationAttributes, OcpiEndpointsAuthorizationActions {
}

export interface OcpiEndpointsAuthorizationActions extends DataResultAuthorizationActions {
  canPing?: boolean;
  canGenerateLocalToken?: boolean;
}

export interface OcpiEndpointAuthorizationActions extends AuthorizationActions {
  canPing?: boolean;
  canGenerateLocalToken?: boolean;
  canRegister?: boolean;
  canTriggerJob?: boolean;
}

export interface OicpEndpointsAuthorizations extends DataResultAuthorizationAttributes, OicpEndpointsAuthorizationActions {
}

export interface OicpEndpointsAuthorizationActions extends DataResultAuthorizationActions {
  canPing?: boolean;
}

export interface OicpEndpointAuthorizationActions extends AuthorizationActions {
  canPing?: boolean;
  canTriggerJob?: boolean;
}

export interface StatisticDataResult extends DataResult<StatisticData>, StatisticsAuthorizations {
}

export interface UserGroupsAuthorizations extends DataResultAuthorizationAttributes, UserGroupsAuthorizationActions {
}

export interface UserGroupsAuthorizationActions extends DataResultAuthorizationActions {
}

export interface UserGroupAuthorizationActions extends AuthorizationActions {
  canMaintainPricingDefinitions?: boolean;
  canAssignUsers?: boolean;
  canUnassignUsers?: boolean;
  canListUsers?: boolean;
}

export interface StatisticsAuthorizations extends DataResultAuthorizationAttributes, StatisticsAuthorizationActions {
  canListUsers?: boolean;
  canListChargingStations?: boolean;
  canListSites?: boolean;
  canListSiteAreas?: boolean;
  canExport?: boolean;
}

export interface StatisticsAuthorizationActions extends AuthorizationActions {
}

export enum DialogMode {
  EDIT = 'E',
  CREATE = 'C',
  VIEW = 'V',
}

export interface DialogData {
  id: string | number;
}

export interface DialogParams<T extends DialogData> {
  dialogData?: T;
  dialogMode?: DialogMode;
  dialogStaticFilters?: Record<string, any>;
}

// Additional auth parameter from DialogParams
export interface DialogParamsWithAuth<T extends DialogData, U extends DataResultAuthorizationAttributes>
  extends DialogParams<T> {
  authorizations?: U;
}

export interface BillingAccountAuthorizationActions extends AuthorizationActions {
  canOnboard?: boolean;
}

export interface BillingTransferAuthorizationActions extends AuthorizationActions {
  canTransfer?: boolean;
  canDownload?: boolean;
}

export interface SchedulerTaskAuthorizationActions extends AuthorizationActions {
}

export interface BlacklistAuthorizationActions extends AuthorizationActions {
}
