import { TableData } from 'types/Table';

import { ChargingSchedule, ChargingStationChargingProfile, Profile } from '../ChargingProfile';

export interface OCPPCommandParam {
}

export interface OCPPResetCommandParam extends OCPPCommandParam {
  type: OCPPResetType;
}

export interface OCPPResetCommandResult {
  status: OCPPResetStatus;
}

export enum OCPPStatus {
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected'
}

export enum OCPPChargingRateUnit {
  WATT = 'W',
  AMPERE = 'A',
}

export enum OCPPResetStatus {
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected'
}

export enum OCPPResetType {
  HARD = 'Hard',
  SOFT = 'Soft'
}

export interface OCPPClearCacheCommandResult {
  status: OCPPClearCacheStatus;
}

export enum OCPPClearCacheStatus {
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected'
}

export interface OCPPGetConfigurationCommandParam extends OCPPCommandParam {
  key?: string[];
}

export interface OCPPGetConfigurationCommandResult {
  configurationKey: OCPPParameter[];
  unknownKey?: string[];
}

export interface OCPPChangeConfigurationCommandParam extends OCPPCommandParam {
  key: string;
  value: string;
}

export interface OCPPChangeConfigurationCommandResult {
  status: OCPPConfigurationStatus;
}

export enum OCPPConfigurationStatus {
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  REBOOT_REQUIRED = 'RebootRequired',
  NOT_SUPPORTED = 'NotSupported'
}

export interface OCPPRemoteStartTransactionCommandParam extends OCPPCommandParam {
  connectorId: number;
  idTag: string;
  chargingProfile?: ChargingStationChargingProfile;
}

export interface OCPPRemoteStartTransactionCommandResult {
  status: OCPPRemoteStartStopStatus;
}

export interface OCPPRemoteStopTransactionCommandParam extends OCPPCommandParam {
  transactionId: number;
}

export interface OCPPRemoteStopTransactionCommandResult {
  status: OCPPRemoteStartStopStatus;
}

export enum OCPPRemoteStartStopStatus {
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected'
}

export interface OCPPUnlockConnectorCommandParam extends OCPPCommandParam {
  connectorId: number;
}

export interface OCPPUnlockConnectorCommandResult {
  status: OCPPUnlockStatus;
}

export enum OCPPUnlockStatus {
  UNLOCKED = 'Unlocked',
  UNLOCK_FAILED = 'UnlockFailed',
  NOT_SUPPORTED = 'NotSupported'
}

export interface OCPPSetChargingProfileCommandParam extends OCPPCommandParam {
  connectorId: number;
  csChargingProfiles?: Profile;
}

export interface OCPPSetChargingProfileCommandResult {
  status: OCPPChargingProfileStatus;
}

export enum OCPPChargingProfileStatus {
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  NOT_SUPPORTED = 'NotSupported'
}

export interface OCPPGetCompositeScheduleCommandParam extends OCPPCommandParam {
  connectorId: number;
  duration: number;
  chargingRateUnit?: OCPPChargingRateUnit;
}

export interface OCPPGetCompositeScheduleCommandResult {
  status: OCPPGetCompositeScheduleStatus;
  connectorId?: number;
  scheduleStart?: Date;
  chargingSchedule?: ChargingSchedule;
}

export enum OCPPGetCompositeScheduleStatus {
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected'
}

export interface OCPPClearChargingProfileCommandParam extends OCPPCommandParam {
  id?: number;
  connectorId?: number;
  chargingProfilePurpose?: OCPPChargingProfilePurpose;
  stackLevel?: number;
}

export interface OCPPClearChargingProfileCommandResult {
  status: OCPPClearChargingProfileStatus;
}

export enum OCPPClearChargingProfileStatus {
  ACCEPTED = 'Accepted',
  UNKNOWN = 'Unknown'
}

export enum OCPPChargingProfilePurpose {
  CHARGE_POINT_MAX_PROFILE = 'ChargePointMaxProfile',
  TX_DEFAULT_PROFILE = 'TxDefaultProfile',
  TX_PROFILE = 'TxProfile'
}

export interface OCPPChangeAvailabilityCommandParam extends OCPPCommandParam {
  connectorId: number;
  type: OCPPAvailability;
}

export interface OCPPChangeAvailabilityCommandResult {
  status: OCPPAvailabilityStatus;
}

export enum OCPPAvailability {
  INOPERATIVE = 'Inoperative',
  OPERATIVE = 'Operative'
}

export enum OCPPAvailabilityStatus {
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  SCHEDULED = 'Scheduled'
}

export interface OCPPGetDiagnosticsCommandParam extends OCPPCommandParam {
  location: string;
  retries?: number;
  retryInterval?: number;
  startTime?: Date;
  stopTime?: Date;
}

export interface OCPPGetDiagnosticsCommandResult {
  fileName?: string;
}

export interface OCPPUpdateFirmwareCommandParam extends OCPPCommandParam {
  location: string;
  retries?: number;
  retrieveDate: Date;
  retryInterval?: number;
}

export interface OCPPParameter extends TableData {
  key: string;
  readonly?: boolean;
  value?: string;
  type?: string;
  component?: string;
  custom?: boolean;
}

export enum OCPPRequestedMessage {
  BOOT_NOTIFICATION = 'BootNotification',
  DIAGNOSTICS_STATUS_NOTIFICATION = 'DiagnosticsStatusNotification',
  FIRMWARE_STATUS_NOTIFICATION = 'FirmwareStatusNotification',
  HEARTBEAT = 'Heartbeat',
  METER_VALUES = 'MeterValues',
  STATUS_NOTIFICATION = 'StatusNotification',
  PUBLISH_FIRMWARE_STATUS_NOTIFICATION = 'PublishFirmwareStatusNotification',
  SIGN_CHARGING_STATION_CERTIFICATE = 'SignChargingStationCertificate',
  SIGN_COMBINED_CERTIFICATE = 'SignCombinedCertificate',
  SIGN_V2G_CERTIFICATE = 'SignV2GCertificate',
  TRANSACTION_EVENT = 'TransactionEvent',
}

export enum OCPP201DataType {
  STRING = 'string',
  DECIMAL = 'decimal',
  INTEGER = 'integer',
  DATE_TIME = 'dateTime',
  BOOLEAN = 'boolean',
  OPTION_LIST = 'OptionList',
  SEQUENCE_LIST = 'SequenceList',
  MEMBER_LIST = 'MemberList',
}
